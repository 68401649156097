/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/sass/global.scss"
import React from 'react'
import {DataProvider} from './src/context/DataContext'
 import Chat from "./src/components/chatbot"

export const wrapRootElement = ({ element }) => (
    <DataProvider>{element}
    <Chat></Chat>
    </DataProvider>
  )