import React,{createContext, useState} from 'react'

export const DataContext = createContext({
    indexTab:0,
    setIndexTab:()=>{},
    typeCabin:{},
    setTypeCabin:()=>{},
    cabinTypeId:{},
    setCabinTypeId:()=>{},
    customer:{},
}); 

export const DataProvider = ({children}) =>{

    const [indexTab, setIndexTab] = useState(0);
    const [typeCabin, setTypeCabin] = useState({});
    const [booking, setBooking] = useState({});
    const [customer, setCustomer] = useState({});
    const [optionForm, setOptionForm] = useState({});
    const [cabinTypeId, setCabinTypeId] = useState(null);
    return (
        <DataContext.Provider value={{indexTab,setIndexTab,typeCabin, setTypeCabin,booking, 
        setBooking,customer, setCustomer,optionForm, setOptionForm,cabinTypeId,setCabinTypeId}}>
            {children}
        </DataContext.Provider>
    )
}
