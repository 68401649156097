import React from 'react';

const SERVER = "https://apibot.waitnrest.com/"
//const SERVER = "http://localhost:3000/"

export const fetchData = async () => {
  const API_SERVER = SERVER + "getMensaje"
  let em = localStorage.getItem("dataUser");
  //console.log(em);
  try {
    const response = await fetch(API_SERVER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: ((em != undefined && em != "" && em != null && em != "null") ? JSON.parse(em).email : "") }),
    });
    const data = await response.json();
    return data.mensaje;
  } catch (error) {
    console.error(error);
    console.error('Error al obtener los datos:', error);
    throw error;
  }
};

export const sendMessage = async (mensaje) => {
  const API_SERVER = SERVER + "sendMensaje";
  try {
    const datos ={
      userMensaje: mensaje,
      dataUser : JSON.parse(localStorage.getItem("dataUser")),
      language: localStorage.getItem("gatsby-i18next-language")
    }
    const response = await fetch(API_SERVER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datos),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error al enviar el mensaje:", error);
    throw error;
  }
};

export const sendDataUser = async (dataUser) => {
  const API_SERVER = SERVER + "sendDataUser";
  try {
    const response = await fetch(API_SERVER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dataUser }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error al enviar el mensaje:", error);
    throw error;
  }
};

