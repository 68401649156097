import React, { useState, useEffect, useRef } from "react";
import { fetchData, sendMessage, sendDataUser } from '../../services/apiService';
import MyImage from "../../images/avatar-success.png";
import SendMensaje from "../../images/send-mensaje.png"
import "./chatbot.css"
import { element } from "prop-types";


const Chat = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false); //estado para controlar la carga de la API
    const messagesContainerRef = useRef(null);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(true); //estado para controlar la visibilidad del formulario

    const [name, setName] = useState(""); // Nuevo estado para almacenar el nombre
    const [email, setEmail] = useState(""); // Nuevo estado para almacenar el correo

    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    // const myDivRef = useRef(null);


    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //       if (myDivRef.current && !myDivRef.current.contains(event.target)) {
    //         console.log('Se presionó fuera del div');
    //         // Aquí puedes llamar a la función que deseas ejecutar

    //       }
    //     };

    //     document.addEventListener('click', handleClickOutside);

    //     return () => {
    //       document.removeEventListener('click', handleClickOutside);
    //     };
    //   }, []);


    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem("dataUser")
        }
        window.addEventListener("beforeunload", handleBeforeUnload)

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [])

    useEffect(() => {
        if (isChatOpen && isFormVisible) {
            inputRef.current.focus();
        }

        if (isChatOpen && isFormVisible == false) {
            inputRef2.current.focus();
        }
    }, [isChatOpen]);




    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
        if (localStorage.getItem("dataUser") !== null) {
            setIsFormVisible(false); // ocultar el formulario al abrir el chats
        } else {
            setIsFormVisible(true); // Mostrar el formulario al abrir el chats
        }
    };

    const closeChat = () => {
        const respuesta = window.confirm('¿Desea finalizar el chat?');
        if (respuesta) {
            //console.log("Cerrando chat")
            localStorage.removeItem("dataUser")
            setIsChatOpen(false)
        } else {
            //console.log("minimizar chat")
        }
    }

    const handleBlur = () => {
    }

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        if (name === "" || email === "") {
            //console.log("Campos vacíos, no se puede enviar el formulario");
        } else {
            const dataUser = {
                name: name,
                email: email
            }
            localStorage.setItem("dataUser", JSON.stringify(dataUser))

            setIsFormVisible(false); // Ocultar el formulario al enviarlo
            await sendDataUser(dataUser)
            setIsChatOpen(true); // Abrir el chat después de enviar el formulario
            setIsLoading(true)
            setTimeout(() => {
                getData()
                setIsLoading(false)
                inputRef2.current.focus();
            }, 50);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const mensaje = event.target.elements.mensaje.value;
        if (mensaje === "") {
            //console.log("mensaje vacío, no se puede enviar");
        } else {
            //console.log("mensaje:", mensaje);
            event.target.elements.mensaje.value = ""
            try {
                setInputDisabled(true);
                // Agregar el mensaje al estado local inmediatamente
                const newMessage = {
                    role: "user",
                    content: mensaje
                };

                const DataUser = localStorage.getItem("dataUser")
                //console.log(JSON.stringify(DataUser))
                setMessages([...messages, newMessage]);
                scrollToBottom();

                // Enviar el mensaje al servidor
                setIsLoading(true);
                await sendMessage(mensaje);

                // Obtener los datos actualizados y reemplazar el mensaje
                getData();
                event.target.elements.mensaje.value = "";
                setInputDisabled(false);
                setIsLoading(false);
                inputRef2.current.focus();
            } catch (error) {
                console.error("Error al enviar el mensaje:", error);
                setIsLoading(false);
            }

        }

    };


    const getData = async () => {
        try {
            const data = await fetchData();
            //console.log(data);
            const filteredMessages = data.filter(
                (message) => message.role !== "system"
            );
            //console.log(filteredMessages);
            setMessages(filteredMessages);
            scrollToBottom();
        } catch (error) {
            console.error("Error al obtener los datos:", error);
        }
    };

    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            const { scrollHeight, clientHeight } = messagesContainerRef.current;
            const maxScrollTop = scrollHeight - clientHeight;
            messagesContainerRef.current.scrollTo({
                top: maxScrollTop,
                behavior: "smooth"
            });
        }
    };

    let windowHeight = window.innerHeight;

    function handleKeyboardEvent() {
        const newWindowHeight = window.innerHeight;

        if (newWindowHeight < windowHeight) {

            try {
                const elemento = document.getElementById('cuerpo-mensaje');
                elemento.classList.add('teclado-active');
                elemento.classList.remove('teclado-inactive');
                setTimeout(() => {
                    scrollToBottom();
                }, 500);
            } catch (error) {

            }
        } else {

            try {
                const elemento = document.getElementById('cuerpo-mensaje');
                elemento.classList.add('teclado-inactive');
                elemento.classList.remove('teclado-active');
                setTimeout(() => {
                    scrollToBottom();
                }, 500);
            } catch (error) {

            }
        }

        windowHeight = newWindowHeight;
    }

    var isScrollAtBottom = false;

    window.addEventListener('scroll', function () {
        var bodyHeight = document.body.scrollHeight;
        var windowHeight = window.innerHeight;
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop + windowHeight >= bodyHeight) {
            if (!isScrollAtBottom) {
                isScrollAtBottom = true;
                let elemento = document.getElementById("chat-minimizado")
                elemento.classList.add("chat-end")
            }
        } else {
            if (isScrollAtBottom) {
                isScrollAtBottom = false;
                let elemento = document.getElementById("chat-minimizado")
                elemento.classList.remove("chat-end")
            }
        }
    });






    window.addEventListener('resize', handleKeyboardEvent);

    return (
        <div >
            {isChatOpen ? (
                <div className="chatContainer">
                    {isFormVisible ? ( // Mostrar el formulario inicial dentro del chat
                        <div className="formContainer">
                            <div className="header">
                                <div>ChatBot Wait N Rest</div>
                                <div className="btnMinimizar" onClick={toggleChat}>—</div>
                            </div>

                            <form className="form-start" onSubmit={handleSubmitForm}>
                                <div className="mensaje-form">Para iniciar el chat, escriba su nombre y su correo.</div>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Ingrese su nombre"
                                    className="input-form-start"
                                    autoComplete="off"
                                    autofocus="true"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    ref={inputRef}
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Correo Electrónico"
                                    className="input-form-start"
                                    autoComplete="off"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button type="submit" className="btnOrange">¡EMPEZAR</button>
                            </form>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="header">
                                <div>ChatBot Wait N Rest</div>
                                <div className="container-action">
                                    <div className="btnMinimizar" onClick={toggleChat}>—</div>
                                    <div className="btnCerrar" onClick={closeChat}>x</div>
                                </div>
                            </div>
                            <div id="cuerpo-mensaje" className="body teclado-inactive" ref={messagesContainerRef}>
                                {messages.map((message, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={message.role === "assistant" ? "containerMensajeRecibido" : "containerMensajeEnviado"}
                                        >
                                            <div
                                                className={message.role === "assistant" ? "colitaReceived" : "none"}
                                            ></div>
                                            <div
                                                className={message.role === "assistant" ? "mensajeRecibido" : "mensajeEnviado"}>
                                                {message.content}
                                            </div>
                                            <div
                                                className={message.role === "assistant" ? "none" : "colitaSend"}
                                            ></div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="sendMensaje">
                                <form className="formSendMensaje" onSubmit={handleSubmit}>
                                    <input
                                        placeholder="Aa"
                                        className="mensaje-entrada"
                                        name="mensaje"
                                        autoComplete="off"
                                        ref={inputRef2}
                                        disabled={inputDisabled}
                                    />
                                    <button
                                        type="submit"
                                        className="boton-mensaje-entrada">
                                        <img src={SendMensaje} style={{ height: "25px" }} />
                                    </button>
                                </form>
                                <div id="preloader_4" style={{ opacity: isLoading ? 1 : 0 }}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            ) : (
                <div className="minimizedChat" onClick={toggleChat} id="chat-minimizado">
                    <div style={{ display: "flex", justifyContent: "center" }} className="animation-panda">
                        <img src={MyImage} style={{ width: "10%", marginRight: "10px" }} />
                        <div style={{ fontSize: "17px", fontWeight: "600" }}>Chat en línea</div>
                    </div>
                </div>
            )}
        </div>
    );
};




export default Chat